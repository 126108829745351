<template>
    <div class="wrapper">

        <router-view></router-view>

        <!-- HeaderNav section -->
        <HeaderNav></HeaderNav>

        <!-- Breadcrumb section -->
        <Breadcrumb breadcrumbDetail="We're creating a new form of AI algorithm, most of the people will call it life." breadcrumbName="About Us"></Breadcrumb>

        <!-- Desc section -->
        <AboutUsDesc></AboutUsDesc>

        <!-- Founder -->
        <Founder></Founder>

        <!-- Founder2 -->
        <Founder2></Founder2>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import FooterSection from '@/components/FooterSection.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import AboutUsDesc from '@/components/AboutUsDesc.vue'
import Founder from '@/components/Founder.vue'
import Founder2 from '@/components/Founder2.vue'

export default {
    components: {
        HeaderNav,
        BannerTwo,
        FooterSection,
        Breadcrumb,
        AboutUsDesc,
        Founder,
        Founder2
    }
}
</script>
