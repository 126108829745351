<template>
    <div class="breadcrumb-area breadcrumb-height" data-bg-image="" :style="{backgroundImage: `url(${ detailBg })`}">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-lg-12">
                    <div class="breadcrumb-item">
                        <h6 class="breadcrumb-title">{{breadcrumbName}}</h6>
                        <h1 class="breadcrumb-detail">{{breadcrumbDetail}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-flex row">
            <div class="col-lg-10 col-md-12">
                <div class="">
                    <h6 class="text-center mt-10" style="font-family: 'Exo 2'; font-weight: 500;">Trusted by Many Companies</h6>
                    <img src="images/hero/nvidia-.png" alt="Hero Image">
                    <img src="images/hero/biznet- 1.png" alt="Hero Image">
                    <img src="images/hero/Hikvision-.png" alt="Hero Image">
                    <img src="images/hero/hero-mcd.png" alt="Hero Image">
                    <img src="images/hero/hero-paramount.png" alt="Hero Image">
                    <img src="images/hero/loket-.png" alt="Hero Image">
                    <img src="images/hero/pawoon-.png" alt="Hero Image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['breadcrumbName', 'breadcrumbTitle', 'breadcrumbDetail'],
  data () {
    return {
      introBg: 'images/breadcrumb/bg/bg.png',
      detailBg: 'images/breadcrumb/bg/detail-bg2.png'
    }
  }
}
</script>
<style scoped>
.breadcrumb-area {
    background-color: #0D0D2D;
}
.breadcrumb-title {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-top: 30px;
}
.breadcrumb-detail {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.03em;
}

.display-flex {
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: -80px;
}

.display-flex div {
    background-color: white;
    padding: 12px 38px;
    z-index: 10;
    border-radius: 12px;
}

.display-flex img {
    padding: 15px 15px;
}

@media only screen and (max-width: 991px) {
    .breadcrumb-title {
        font-size: 16px;
    }

    .breadcrumb-height {
        height: 550px;
    }

    .display-flex {
        height: 0px;
        width: 90%;
        display: flex;
        margin-top: -40px;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .display-flex div {
        text-align: center;
        background-color: white;
        padding: 12px 38px;
        z-index: 10;
        border-radius: 12px;
    }
    
    .display-flex img {
        width: 30%;
        padding: 5px 5px;
    }
}
</style>
