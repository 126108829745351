<template>
    <!-- Begin Feature Area -->
    <div id="feature" class="feature-area feature-style-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-area">
                        <h2 class="section-title">Founder Riset.ai</h2>
                        <div class="banner-area banner-style-4">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <div class="banner-img">
                                            <div class="img">
                                                <img src="images/founder/ceo.png" alt="Banner">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 desktop">
                                        <div class="banner-content" style="padding-top: 0px;">
                                            <span class="text-purple">CEO</span>
                                            <h2 class="text-blue">Budi Rahardjo</h2>
                                            <p class="banner-desc" style="font-size: 18px; line-height: 1.8; font-weight: 400; margin-bottom: 0px;">
                                                Acting as our CEO, Budi Rahardjo, PhD is a lecturer in ITB, School of Electrical Engineering and Informatics. 
                                                He was the IT Architect behind Bank Indonesia’s IT Architecture and Security, and Head of Team for developing 
                                                the National Standard for IT competence in computer network, system administrator, and technical support. 
                                                Nowadays he is also mentor to various startups across Bandung and Jakarta
                                            </p>
                                            <div class="btn-wrap" style="margin-top: 20px;">
                                                <a class="btn purple-color" href="https://www.linkedin.com/in/budi-rahardjo-3a54791/" target="_blank"><i class="icofont-linkedin" style="padding-right: 8px;"></i>LinkedIn</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Features Area End Here -->
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination } from 'swiper'
SwiperCore.use([Pagination])

export default {
    components: { 
        VideoBackground ,
        Swiper,
        SwiperSlide
    },
    data () {
        return {
        orders: [
            {
            featureImage: 'images/feature/useful.png',
            title: 'Useful',
            description: 'Meets a real market need and/or corrects significant inefficiencies as a result of over-reliance on human effort'
            },
            {
            featureImage: 'images/feature/practical.png',
            title: 'Practical',
            description: 'Needs to be implementable on a mass-market basis, i.e. have commercial value/appeal in the real-world economy'
            },
            {
            featureImage: 'images/feature/timely.png',
            title: 'Timely',
            description: 'Tackles immediate problems rather than focus on revolutionary (often costly) ideas that completely disrupt existing norms'
            },
        ],
        swiperOptions: {
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                1200: {
                    slidesPerView: 3,
                }
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets'
            }
        },
        currentPage: 2,
        maxPerPage: 2,
        showReadMore: true
        }
    },
    computed: {
        totalResults () {
        return Object.keys(this.orders).length
        },
        pageCount () {
        return Math.ceil(this.totalResults / this.maxPerPage)
        },
        pageOffest () {
        return this.maxPerPage * this.currentPage
        },
        paginatedOrders () {
        return this.orders.slice(0, this.currentPage * this.maxPerPage)
        }
    },
    methods: {
        loadMore () {
        this.currentPage += 1
        }
    }
}
</script>
<style scoped>
.feature-style-5{
    background-color: #F3F6FD;
    height: 620px;
}
.section-desc {
    color: #57617b;
    font-size: 20px;
    line-height: 42px;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}
.section-title{
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 34px;
    /* identical to box height */

    letter-spacing: 0.03em;
    margin-bottom: -60px;
    margin-top: -60px;
}
.section-title-h3{
    font-size: 30px;
    line-height: 100px;
    color: #000000;
    font-size: 20px;
    line-height: 42px;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.feature-style-5 .feature-item {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-radius: 30px;
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
    background-color: #ffffff;
}
.feature-style-5 .feature-iconn {
    background-color: #f4f6ff;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    line-height: 110px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.feature-style-5 .feature-content .title {
    margin-bottom: 20px;
    color: #07135b;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */

    letter-spacing: 0.03em;
}
.feature-style-5 .feature-content .desc {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    text-align: center;
}
.text-purple {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    display: block;
    color: #CE097C;
}
.banner-style-4 .banner-content {
    padding-left: 30px;
    -webkit-align-self: left;
        -ms-flex-item-align: left;
            align-self: left;
    text-align: left;
    margin-top: 20px;
}
.banner-desc {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    color: #383C4A;
}
.btn.purple-color {
    background-color: #CE097C;
    color: #ffffff;
    font-family: 'Exo 2';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    border-radius: 50px;
    padding: 1rem 0px;
    width: 210px;
    height: 55px;
    line-height: 0px;
}
.btn.purple-color:hover {
    background-color: transparent;
    color: #CE097C;
    border-color: #CE097C;
}
.text-blue {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 1000;
    font-size: 48px;
    line-height: 58px;
    display: block;
    color: #25376F;
}
@media (min-width: 1200px) {
    /* .mobile {
        display: none;
    } */
    
    .display-flex {
        height: 0px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: -80px;
    }
    
    .display-flex div {
        background-color: white;
        padding: 12px 38px;
        z-index: 10;
        border-radius: 12px;
    }
    
    .display-flex img {
        padding: 15px 15px;
    }
    
    .banner-img .img img{
        box-shadow: -15px 15px 5px grey;
        border-top-right-radius: 60px;
        position: relative;
    }
    
    .banner-content {
        padding: 0px;
        margin-top: 100px;
    }
}

@media (max-width: 991px) {
    .feature-style-5{
        background-color: #F3F6FD;
        height: 1120px;
    }

    .banner-img .img img{
        box-shadow: -15px 15px 5px grey;
        border-top-right-radius: 60px;
        position: relative;
    }

    .banner-style-4 .banner-content {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 574px) {
    .feature-style-5{
        background-color: #F3F6FD;
        height: 1150px;
    }

    .banner-style-4 .banner-content {
        padding-left: 0px;
    }
}
</style>
