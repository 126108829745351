<template>
    <!-- Begin Feature Area -->
    <video-background
    src="images/banner/banner-section-top.mp4"
    style="width: 100%;"
    class="hero-area hero-bg hero-style-1"
    >
        <div id="feature" class="feature-area feature-style-5">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-area">
                            <h2 class="section-title">What is Riset.ai</h2>
                            <p class="section-desc">
                                PT Riset Kecerdasan Buatan (“RKB”) is a company that focuses on the research and development of AI solutions (Computer Vision) 
                                for a wide range of industries that are adapting to Indonesia’s rapidly digitalizing economy. RKB’s solutions have garnered 
                                interest in both the public and private sector.
                            </p>
                            <h3 class="section-title-h3">
                                Recognizing the opportunities of the current state of the Indonesian economy, 
                                RKB builds AI solutions grounded in 3 core principles:
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="row feature-slider-2 mt-n30 desktop">
                    <swiper class="swiper-container feature-slider"
                        :breakpoints="swiperOptions.breakpoints"
                        :slides-per-view="3"
                        :spaceBetween="30"
                        :loop="false"
                        @swiper="onSwiper"
                    >
                        <swiper-slide  v-for="(slider, sliders) in orders" :key="sliders" class="feature-item mt-30">
                            <div class="feature-iconn">
                                <img :src="`${slider.featureImage}`" :alt="slider.alt">
                            </div>
                            <div class="feature-content">
                                <h3 class="title">{{ slider.title }}</h3>
                                <p class="desc">{{ slider.description }}</p>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="row feature-slider-2 mt-n30 mobile">
                    <div class="swiper-container feature-slider">
                        <div v-for="(slider, index) in orders" :key="index" class="col-lg-6 col-sm-12 feature-item mt-30">
                            <div class="feature-iconn">
                                <img :src="`${slider.featureImage}`" :alt="slider.alt">
                            </div>
                            <div class="feature-content">
                                <h3 class="title">{{ slider.title }}</h3>
                                <p class="desc">{{ slider.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </video-background>
    <!-- Features Area End Here -->
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination } from 'swiper'
SwiperCore.use([Pagination])

export default {
    components: { 
        VideoBackground ,
        Swiper,
        SwiperSlide
    },
    data () {
        return {
        orders: [
            {
            featureImage: 'images/feature/useful.png',
            title: 'Useful',
            description: 'Meets a real market need and/or corrects significant inefficiencies as a result of over-reliance on human effort'
            },
            {
            featureImage: 'images/feature/practical.png',
            title: 'Practical',
            description: 'Needs to be implementable on a mass-market basis, i.e. have commercial value/appeal in the real-world economy'
            },
            {
            featureImage: 'images/feature/timely.png',
            title: 'Timely',
            description: 'Tackles immediate problems rather than focus on revolutionary (often costly) ideas that completely disrupt existing norms'
            },
        ],
        swiperOptions: {
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                1200: {
                    slidesPerView: 3,
                }
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets'
            }
        },
        currentPage: 2,
        maxPerPage: 2,
        showReadMore: true
        }
    },
    computed: {
        totalResults () {
        return Object.keys(this.orders).length
        },
        pageCount () {
        return Math.ceil(this.totalResults / this.maxPerPage)
        },
        pageOffest () {
        return this.maxPerPage * this.currentPage
        },
        paginatedOrders () {
        return this.orders.slice(0, this.currentPage * this.maxPerPage)
        }
    },
    methods: {
        loadMore () {
        this.currentPage += 1
        }
    }
}
</script>
<style scoped>
.hero-style-1.hero-bg {
    height: 980px;
}
.section-desc {
    color: #57617b;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 42px;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}
.section-title{
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 34px;
    /* identical to box height */
    margin-top: 40px;

    letter-spacing: 0.03em;
}
.section-title-h3{
    font-size: 30px;
    line-height: 100px;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    color: #000000;
    font-size: 20px;
    line-height: 42px;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.feature-style-5 .feature-item {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-radius: 30px;
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
    background-color: #ffffff;
}
.feature-style-5 .feature-iconn {
    background-color: #f4f6ff;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    line-height: 110px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.feature-style-5 .feature-content .title {
    margin-bottom: 20px;
    color: #07135b;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */

    letter-spacing: 0.03em;
}
.feature-style-5 .feature-content .desc {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    text-align: center;
}

.mobile {
    display: none;
}
@media only screen and (max-width: 991px) {
    .hero-style-1.hero-bg {
        height: 1900px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .feature-style-5 .feature-iconn {
        background-color: #f4f6ff;
        border-radius: 50%;
        width: 140px;
        height: 140px;
        line-height: 110px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
    }

    .feature-style-5 .feature-content .title {
        font-size: 32px;
    }
    .feature-style-5 .feature-content .desc {
        font-size: 18px;
    }
}
@media only screen and (max-width: 574px) {
    .hero-style-1.hero-bg {
        height: 1900px;
    }

    .section-desc {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
    .section-title{
        font-family: 'Exo 2';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 34px;
        /* identical to box height */
        margin-top: 40px;

        letter-spacing: 0.03em;
    }
    .section-title-h3{
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
    .feature-item {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
